import React, {ReactNode} from 'react';
import Jamie from "../../assets/jamie.jpeg"
import Logo from "../../assets/logo.png"
import "./footer.scss"


export const Footer = (props: {}) => (
    <div className={"footer-container"}>
        <div className={"footer-sections"}>
            <div className={"left"}>
                <img src={Logo} className={"logo"}/>
                <div>
                    <h2>Phone Number</h2>
                    <p>(703) 973-1334</p>
                </div>
                <div>
                    <h2>Email</h2>
                    <p>trippinwithjamie@gmail.com</p>
                </div>
            </div>
            <div className={"right"}>
                <img src={Jamie}/>
                <div className={"content"}>
                    <h1>Jamie Scheff</h1>
                    <span>Certified Travel Agent</span>
                    <span>Years of experiences</span>
                    <span>Over 250+ trips planned</span>
                </div>
            </div>
        </div>
    </div>
)