import React from 'react';
import './main.scss';
import Logo from "../../assets/logo.png";

function Main() {
    return (
        <div className="main-container">
            <div className="main-content">
                <h1>Trippin' With Jamie</h1>
                <span>Turn your dreams into memories</span>
            </div>
            <img src={Logo} className="mobile-logo"/>
        </div>
    );
}

export default Main;
