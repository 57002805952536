import React from 'react';
import './App.scss';
import Main from "./components/main/Main";
import { CenterPanel, DisplayPanel } from "./components/panels/Panels";
import Greece from "./assets/greece.jpg"
import Kayak from "./assets/kayak.jpg"
import Hut from "./assets/hut.jpg"
import Waterfall from "./assets/waterfall.jpeg"
import Certifications from "./assets/certifications.jpg"
import Cert from "./assets/cert.png"
import Logo from "./assets/logo.png"
import {Footer} from "./components/footer/Footer";

function App() {
  return (
    <div className="App">
        <img src={Logo} className={"main-logo"}/>
        <Main/>
        <div className={"panel-container"}>
          <CenterPanel cards={[
              {
                  imgSrc: Waterfall,
                  heading: "Expert Planning",
                  text: "We've planned over 250+ vacations all over the world. We can ensure you have the best experience!"
              },
              {
                  imgSrc: Cert,
                  heading: "We Specialize In Special Needs",
                  text: "We are trained and certified to best prepare for those needing special diets, on the autism spectrum, needing low-sensory environments, in a wheelchair, with a service dog, or needing allergy friendly accommodations.",
              },
              {
                  imgSrc: Hut,
                  heading: "We Tailor Your Experience!",
                  text: "You are not just a name and a number to us. Every client will plan their travel with a certified travel agent over phone, email, or text."
              }
          ]}/>
          <DisplayPanel
              heading={"Get Access To Exclusive Deals!"}
              text={
                  <span>
                      We have access to deals that you might not otherwise be able to see. Click <a href={"https://travelagentconnection.com/?agent=20031638"} target="_blank">here</a> to see a sample of what is available!
                  </span>
              }
              imgSrc={Greece}
              left
          />
          <DisplayPanel
              heading={"Don't Miss Out On Any Incredible Adventures!"}
              text={
                  <span>Already have a vacation booked? Spice it up with an adventure! <a
                      href={"http://www.shoreexcursionsgroup.com/Default.asp?Click=130317"} target="_blank">Take a look at what our partners can offer!</a></span>
              }
              imgSrc={Kayak}
          />
          <DisplayPanel
            heading={"Certified To Handle Any Situation"}
            text={"We have extensive experience in planning vacations, even for families with disabilities and allergies"}
            imgSrc={Certifications}
            left
          />
        </div>
        <Footer/>
    </div>
  );
}

export default App;
