import React, {ReactNode} from 'react';
import "./panel.scss"

export interface TwjPanel{
    imgSrc: string,
    heading: string,
    text: ReactNode,
    left?: boolean,
    className?: string
}

export const CenterPanel = (props: { cards: TwjPanel[] }) => (
    <div className={"center-panel"}>
        <h2 className="subheader">
            Want to learn more about how we can make your vacation special? Check out our <a href={"brochure.pdf"} download className={"brochure"}>brochure</a>
        </h2>
        <div className={"card-row"}>
            { props.cards.map ( c => (
                <div key={c.heading.length} className={`twj-card ${c.className}`} >
                    <img src={c.imgSrc}/>
                    <h3>{c.heading}</h3>
                    <div>{c.text}</div>
                </div>
            ))}
        </div>
    </div>
);

export const DisplayPanel = (props: TwjPanel) => {
    if (props.left && props.left == true) {
        return (
            <div className={`display-panel left`}>
                <div className={"img-side"}>
                    <img src={props.imgSrc}/>
                </div>
                <div className={"content-side"}>
                    <h2>{props.heading}</h2>
                    <div>{props.text}</div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={"display-panel"}>
                <div className={"content-side"}>
                    <h2 className={"content-heading"}>{props.heading}</h2>
                    <div className={"content-text"}>{props.text}</div>
                </div>
                <div className={"img-side"}>
                    <img src={props.imgSrc}/>
                </div>
            </div>
        )
    }
};